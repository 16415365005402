<template>
    <el-container style="display: flex;padding-top:74px;">
        <el-aside width="257px">
            <el-menu :default-active="activeIndex" class="menu" router @open="handleOpen" :default-openeds="defaultOpeneds" unique-opened>
                <el-menu-item index="/member/index">
					<i class="el-icon-menu"></i>
                    <span slot="title">工作台</span>
                </el-menu-item>
				<el-menu-item index="/">
					<i class="el-icon-s-goods"></i>
					<span slot="title">商品</span>
					<i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
				</el-menu-item>
				<el-menu-item index="/cart">
					<i class="el-icon-shopping-cart-full"></i>
					<span slot="title">进货单</span>
					<i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
				</el-menu-item>
				<el-menu-item index="/member/order_list">
					<i class="el-icon-set-up"></i>
					<span slot="title">订单管理</span>
					<i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
				</el-menu-item>
				<el-menu-item index="/member/activist">
					<i class="el-icon-warning"></i>
					<span slot="title">售后管理</span>
					<i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
				</el-menu-item>
				<el-menu-item index="/member/sales">
					<i class="el-icon-success"></i>
					<span slot="title">销售管理</span>
					<i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
				</el-menu-item>
			
                <el-submenu index="3" title>
                    <template slot="title">
						<i class="el-icon-s-custom"></i>
                        <span>账户中心</span>
                    </template>
                    <el-menu-item index="/member/account">我的账户</el-menu-item>
					<el-menu-item index="/member/info">基本信息</el-menu-item>
					<el-menu-item index="/member/security">账户安全</el-menu-item>
					<el-menu-item index="/member/delivery_address">收货地址</el-menu-item>
                    <el-menu-item index="/member/withdrawal">提现记录</el-menu-item>
                    <el-menu-item index="/member/my_coupon">我的优惠券</el-menu-item>
					
					<el-menu-item @click="logout">退出登录</el-menu-item>
                    <!-- <el-menu-item index="my_point">我的积分</el-menu-item> -->
					<!-- <el-menu-item index="account_list">账户列表</el-menu-item> -->
                    <!-- <el-menu-item index="collection">我的关注</el-menu-item> -->
                    <!-- <el-menu-item index="footprint">我的足迹</el-menu-item> -->
                    <!-- <el-menu-item index="level">会员等级</el-menu-item> -->
                </el-submenu>
            </el-menu>
        </el-aside>
        <el-main class="member">
			<div style="min-height:700px;">
            <transition name="slide"><router-view /></transition>
			</div>
			<!-- 底部 -->
			<el-footer>
				<copy-right />
			</el-footer>
        </el-main>
    </el-container>
</template>
<script>
    import { checkisverifier } from "@/api/order/verification"
	import CopyRight from "@/layout/components/CopyRight"
    export default {
        name: "home",
        components: {
			CopyRight
		},
        data: () => {
            return {
                defaultOpeneds: ["1"],
                activeIndex: "index",
                is_verify: 1
            }
        },
        created() {
            this.activeIndex = this.$route.meta.parentRouter ||this.$route.path.replace("/member/", "")
            this.checkIsVerifier()
        },
        methods: {
			logout() {
			    this.$store.dispatch("member/logout")
				location.reload()
			},
            handleOpen(key, keyPath) {
                this.defaultOpeneds = keyPath
            },
            checkIsVerifier() {
                checkisverifier()
                    .then(res => {
                        if (res.data) {
                            this.is_verify = 1
                        }
                    })
                    .catch(err => {
                        this.is_verify = 0
                    })
            }
        },
        watch: {
            $route(curr) {
				this.activeIndex = curr.meta.parentRouter||this.$route.path.replace("/member/", "");
            }
        }
    }
</script>
<style lang="scss" scoped>
    .menu {
		min-height:760px;
        height: 100%;
		background: url('../../assets/images/member_left.jpg') repeat-y left top;
    }
    .content {
        display: flex !important;
        margin: 20px 0;
    }
	
    .member {
        margin-left: 15px;
		//padding-top:10px;
		border-top:10px solid #f7f7f7;
		background-color: #fff;
    }
	
    .el-footer {
		//margin-top:10px;
		border-top:10px solid #f7f7f7;
        padding: 0;
        height: auto !important;
        background-color: #fff;
    }
</style>
